import React, { useEffect, useContext, useState, useRef } from "react"
import Loader from 'react-loader-spinner'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import { Router } from '@reach/router'


import Helmet from "react-helmet"
import Loadable from 'react-loadable'


import { slug } from "../lib/utils"
import { getTestimonies } from "../lib/API"
import { useData, useDataOrNull } from "../lib/hooks"
import BannerContext from "../lib/context"
import { navigate } from "gatsby"

import TestimonyPage from '../templates/testimony'
import Header from '../components/Header'

import TestimonyImage from '../assets/images/home/testimonies.jpg'






const Testimonies = () => {

    const siteTitle = "Blackheath and Charlton Baptist Church - Testimonies"
    



    const headerImages = [ TestimonyImage ]
    const headerTitle = "Testimonies"
    // const headerIcon = <span className="icon major"><i className="fas fa-calendar-alt"></i></span>

    const testimonies = useDataOrNull( getTestimonies )
    
    return (
        <>
           
            <div id='testimonies-page'>
            
                <Helmet title={siteTitle} />
                <Header images={headerImages} headerTitle={headerTitle} tint={0.3} />
                <section id="testimonies" className="main style1 special">
                    <div className="grid-wrapper">
                        {!testimonies ?
                            <div className='col-12'>
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                /> 
                            </div>
                            :
                            testimonies.map( testimony =>
                            <div data-aos="zoom-in" data-aos-duration="900" className="col-3 testimony" onClick={() => navigate( `/testimonies/${slug( testimony.title )}`, { state: testimony } )}>
                                <h4><em><b>{moment( testimony.date ).format( 'MMM YYYY' ).toString()}</b></em></h4>
                                <div className='testimony-content'>
                                    <ReactMarkdown children={testimony && testimony.content.substring( 0, 200 )+"..."} />
                                </div>
                            </div>
                
                            ) }
                    </div>
                </section>

            </div>
        </>
    )
}



const Page = () =>  <Router> 
                        <TestimonyPage path="/testimonies/:id" exact />
                        <Testimonies path="/testimonies" exact />
                    </Router>

export default Page