import React from "react"
import Helmet from "react-helmet"
import { navigate } from "@reach/router"
import DOMPurify from 'dompurify'
import ReactMarkdown from 'react-markdown'



import { queryTestimonyTitle } from '../lib/API'
import { useData } from '../lib/hooks'
import moment from 'moment'




export default ( { location } ) => {
    const [ slug ] = typeof window !== 'undefined' ? window.location.href.split( '/' ).slice( -1 ) : [ '' ]
    const queryTitle = decodeURIComponent( slug ).replace( /-/g, ' ' )
    const data =  useData( queryTestimonyTitle,  queryTitle )

    const localData = location.state  || {} 
    // console.log( data )

    
    if( ( Object.keys( localData ).length > 1 ) || data.length > 0 ){
        const { content, date, title } =  location.state || data[0]
        const siteTitle = "Blackheath and Charlton Baptist Church"

        return (
            <div id='testimony-page'>
                <Helmet title={siteTitle} />


                <section id="testimony-content" className="main style1 special">
                    <div className="grid-wrapper">
                        <div className="col-12 testimony-info">
                            <div className="back-button" onClick={() => navigate( '/testimonies' )}><b>&lt;&ensp; Back</b></div>
                            <header className="major">
                                <h1>{title}</h1>
                            </header>
                            <h3><em>{moment( date ).format( 'MMM YYYY' ).toString()}</em></h3>
                        </div>

                        <div className="col-12 testimony-text">
                            <ReactMarkdown children={content} />
                        </div>
                    </div>
                </section>
            </div>
        )
    }else{

        return null
    }
}